import "./team-select.scss";

import React, { useState, useRef, useEffect } from "react";

const getTitle = (option) => {
  switch (option) {
    case "wolomin":
      return "Poradnia w Wołominie";
    case "bialoleka":
      return "Poradnia Warszawa Białołęka";
    case "online":
      return "Online";
    default:
      return "Wszyscy specjaliści";
  }
};

const TeamSelect = ({ chosenOption, setChosenOption }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const locations_list = useRef();

  const title = getTitle(chosenOption);

  useEffect(() => {
    const clickedOutside = (e) => {
      if (accordionOpen && locations_list.current) {
        setAccordionOpen(false);
      }
    };
    document.addEventListener("click", clickedOutside);

    return () => {
      document.removeEventListener("click", clickedOutside);
    };
  }, [accordionOpen]);

  return (
    <div className={`team-select${accordionOpen ? " open" : ""}`}>
      <button
        type="button"
        className="team-select__box"
        onClick={() => setAccordionOpen(!accordionOpen)}
      >
        <span>{title}</span>
        <img
          className="team-select__icon"
          style={accordionOpen ? { transform: "rotate(180deg)" } : null}
          src={require("assets/icons/locations_arrow.svg").default}
          alt=""
        />
      </button>
      <ul className="team-select__list" ref={locations_list}>
        <li
          className={`team-select__list-item${
            chosenOption === null ? " hide" : ""
          }`}
        >
          <button type="button" onClick={() => setChosenOption(null)}>
            Wszyscy specjaliści
          </button>
        </li>
        <li
          className={`team-select__list-item${
            chosenOption === "wolomin" ? " hide" : ""
          }`}
        >
          <button type="button" onClick={() => setChosenOption("wolomin")}>
            Poradnia w Wołominie
          </button>
        </li>
        <li
          className={`team-select__list-item${
            chosenOption === "bialoleka" ? " hide" : ""
          }`}
        >
          <button type="button" onClick={() => setChosenOption("bialoleka")}>
            Poradnia Warszawa Białołęka
          </button>
        </li>
        <li
          className={`team-select__list-item${
            chosenOption === "online" ? " hide" : ""
          }`}
        >
          <button type="button" onClick={() => setChosenOption("online")}>
            Online
          </button>
        </li>
      </ul>
    </div>
  );
};

export default TeamSelect;
