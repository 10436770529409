import "styles/pages/page-team.scss";
import React, { useState } from "react";
import { Link, graphql } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import Divider from "components/Divider";
import PersonTile from "components/PersonTile";
import Breadcrumps from "components/Breadcrumps";
import TeamSelect from "components/TeamSelect";
import BookingModal from "../components/BookingModal";

let filtered_persons = [];

const TeamPage = ({ pageContext, data }) => {
  const [query, setQuery] = useState("");
  const [chosenOption, setChosenOption] = useState(null);
  const [bookingModal, setBookingModal] = useState(false);

  // const team = data?.allWpPage?.nodes[0]?.teamPage?.team;
  const pageData = pageContext.data;
  const seo = pageContext.seo;
  const specialists = data.allWpZespol.nodes;

  if (chosenOption === null) {
    filtered_persons = specialists;
  } else {
    filtered_persons = specialists?.filter((person) =>
      person?.team?.location?.includes(chosenOption)
    );
  }

  return (
    <Layout>
      <Seo title={seo.title} description={seo.metaDesc} />
      <Breadcrumps data="Zespół" />
      <SubpageHeader title={pageData.mainTitle} />
      <section className="team-about">
        <div className="container">
          <h2>{pageData.section1Title}</h2>
          <Divider marginTop={15} marginBottom={45} />
          <div
            className="team-about__text"
            dangerouslySetInnerHTML={{ __html: pageData.section1Text }}
          />
        </div>
      </section>
      <section className="team-persons">
        <div className="container">
          <h2>Zespół specjalistów w poradni psychologicznej Spokój w Głowie</h2>
          <TeamSelect
            chosenOption={chosenOption}
            setChosenOption={setChosenOption}
          />
          <div className="team-tabs">
            <button
              type="button"
              className={`team-tabs__single${
                chosenOption === null ? " active" : ""
              }`}
              onClick={() => setChosenOption(null)}
            >
              Wszyscy
            </button>
            <button
              type="button"
              className={`team-tabs__single${
                chosenOption === "wolomin" ? " active" : ""
              }`}
              onClick={() => setChosenOption("wolomin")}
            >
              Poradnia w Wołominie
            </button>
            <button
              type="button"
              className={`team-tabs__single${
                chosenOption === "bialoleka" ? " active" : ""
              }`}
              onClick={() => setChosenOption("bialoleka")}
            >
              Poradnia Warszawa Białołęka
            </button>
            <button
              type="button"
              className={`team-tabs__single${
                chosenOption === "online" ? " active" : ""
              }`}
              onClick={() => setChosenOption("online")}
            >
              Online
            </button>
          </div>

          <div className="team-search">
            <div className="contact-form__input-group">
              <input
                name="search"
                id="search"
                type="text"
                className="contact-form__input"
                value={query}
                onChange={({ target: { value } }) => setQuery(value)}
              />
              <label htmlFor="search" className="contact-form__label">
                Znajdź swojego specjalistę
              </label>
            </div>
            <button
              className="button button--gray"
              onClick={() => setQuery("")}
            >
              Wyczyść filtrację
            </button>
          </div>

          <div className="row g-0">
            {filtered_persons
              ?.filter(
                (item) =>
                  item?.team?.fullName?.includes(query) ||
                  item?.team?.fullName?.toLowerCase().includes(query)
              )
              .map((item, index) => (
                <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                  <PersonTile
                    name={item.team.fullName}
                    text={item.team.tileDesc}
                    position={item.team.position}
                    secondPosition={item.team.secondPosition}
                    url={item.uri}
                    img={item.team.img?.sourceUrl}
                    location={item.team.location}
                  />
                </div>
              ))}
          </div>
        </div>
      </section>
      <section className="team-work">
        <div className="container">
          <h2>{pageData.section3Title}</h2>
          <Divider marginTop={15} marginBottom={45} />
          <div
            className="team-work__text"
            dangerouslySetInnerHTML={{ __html: pageData.section3Text }}
          />
        </div>
      </section>
      <section className="team-visit">
        <div className="container">
          <h2>{pageData.section4Title}</h2>
          <Divider marginTop={15} marginBottom={45} />
          <div
            className="team-visit__text"
            dangerouslySetInnerHTML={{ __html: pageData.section4Text }}
          />
          <div className="team-visit__buttons">
            <Link to="/rezerwacja-wizyt/" className="button button--green">
              Umów wizytę
            </Link>
            <button
              onClick={() => setBookingModal(true)}
              className="button button--red"
            >
              Zadzwoń
            </button>
            <Link to="/kontakt/" className="button button--orange">
              Wyślij zapytanie
            </Link>
          </div>
        </div>
      </section>
      {bookingModal && <BookingModal setBookingModal={setBookingModal} />}
    </Layout>
  );
};

export const query = graphql`
  query personsTeam {
    allWpZespol(sort: { order: ASC, fields: date }) {
      nodes {
        uri
        team {
          secondPosition
          position
          fullName
          img {
            sourceUrl
          }
          tileDesc
          location
        }
      }
    }
    allWpPage(filter: { template: { templateName: { eq: "Zespol" } } }) {
      nodes {
        id
        teamPage {
          team {
            ... on WpZespol {
              uri
              team {
                secondPosition
                position
                fullName
                img {
                  sourceUrl
                }
                tileDesc
              }
            }
          }
        }
      }
    }
  }
`;

export default TeamPage;
